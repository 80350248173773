<!--
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-25 22:03:11
 * @LastEditors: v_binqin
 * @LastEditTime: 2022-11-27 01:33:48
-->
<!--  -->
<template>
  <div class="login">
    <div class="logo">
      <img src="../assets/web_logo.png" alt="" width="80px" height="80px" />
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        name="手机号"
        label="手机号"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '' }]"
      ></van-field>
      <van-field
        v-model="sms"
        center
        clearable
        type="password"
        label="密码"
        maxlength="15"
        placeholder="请输入密码"
        :rules="[
          { required: true, message: '' },
          { validator: asyncValidator, message: '请输入密码' },
        ]"
      >
        <!-- <template #button>
          <van-button
            size="small"
            type="info"
            :disabled="disabled"
            @click="sendSms"
          >
            {{ disabled ? time + '秒后重试' : '发送验证码' }}
          </van-button>
        </template> -->
      </van-field>
      <div style="margin: 25px">
        <van-button round block type="info" native-type="submit">
          登录
        </van-button>
      </div>
      <!-- <div @click="regUser" class="div_reg" style="margin: 25px">
        <span>去注册</span>
      </div> -->
    </van-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      phone: '',
      sms: '',
      time: 60,
      disabled: false,
    }
  },
  methods: {
    asyncValidator(val) {
      return val!=null
    },
    sendSms() {
      if (this.isPoneAvailable(this.phone)) {
        this.disabled = true
        this.http
          .get('/mp/user/sendCode', {
            params: {
              phone: this.phone,
              type: 3,
            },
          })
          .then(({ data }) => {
            if (data.code == 200) {
              this.$toast.success('验证码发送成功')
            }
          })
        let timer = setInterval(() => {
          if (this.time == 0) {
            this.time = 60
            clearInterval(timer)
            this.disabled = false
          }
          this.time--
        }, 1000)
      } else {
        this.$toast.fail('请填写正确的手机号')
      }
    },
    onSubmit() {
      this.http
        .post('/login', {
          username: this.phone,
          password: this.sms,
        })
        .then(({ data }) => {
          if (data.status == 0) {
            this.$toast.success('登陆成功')
            localStorage.setItem('sessionId', data.result)
            this.$router.replace('/')
          }else{
            if(data.message =="用户名未知"){
               this.$router.push({path: '/regUser', query: {phoneNumber: this.phone}})
            }
          }
        })
      // localStorage.setItem('sessionId', 123)
      // this.$router.replace('/')
    },
    regUser() {
      this.$router.push('/regUser')
      // this.$router.replace("/");
    },
    isPoneAvailable(telephone) {
      var myreg = /^1[0-9]{10}$/
      if (!myreg.test(telephone)) {
        return false
      } else {
        return true
      }
    },
  },
  created() {},
  mounted() {
    let phone = this.$route.query.phone
    if (phone) {
      this.phone = phone
    }
  },
}
</script>
<style lang="less" scoped>
.login {
  height: 100%;
  position: relative;
  .van-form {
    width: 96%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    margin-top: 180px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.div_reg {
  text-decoration: underline;
  width: 80%;
  text-align: right;
  color: rgb(24, 154, 206);
  .span {
    font-size: 20px;
  }
}
</style>
